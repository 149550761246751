import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import HeroBottomSection from "../components/dynamic-sections/HeroBottomSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"
import HeroRightSection from "../components/dynamic-sections/HeroRightSection"
import ThreeBoxImageSection from "../components/dynamic-sections/ThreeBoxImageSection"
import TwoImageSection from "../components/dynamic-sections/TwoImageSection"
import SixBoxSection from "../components/dynamic-sections/SixBoxSection"
import RickRollNorhartLife from "../components/dynamic-sections/RickRollNorhartLife"
import KeynoteSection from "../components/dynamic-sections/KeynoteSection"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** svg */
import NorhartIcon from "../../assets/norhart-icon.svg"
import ApartmentIcon from "../../assets/apartment-icon.svg"
import ValuesIcon from "../../assets/values.svg"
import ValuesNorhartIcon from "../../assets/value-norhart-logo.svg"
import ValuesRespectIcon from "../../assets/value-complete-respect.svg"
import ValuesResultsIcon from "../../assets/value-pursuit-results.svg"
import ValuesAttitudeIcon from "../../assets/value-attitude.svg"
import ValuesMindsetIcon from "../../assets/value-mindset.svg"
import ValuesReliableIcon from "../../assets/value-reliable.svg"
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"
import StarReviewIcon from "../../assets/five-star-rating.svg"
import VideoIcon from "../../assets/video-icon.svg"

/** props */
interface Props {
  data: any
}

/** const */
const NorhartLifePage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/covid/",
          name: "Norhart Life | Norhart",
          image: `${config.siteMetadata.siteUrl}/norhart-life/norhart-life-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Norhart Life | Norhart"
      description="Experience Norhart life. We build more than just luxury apartments in Forest Lake, Blaine, and Oakdale, MN. Every day we work towards creating a better way for our residents to live."
      keywords="norhart, norhartlife, worklife, work life balance, benefits, employees, forest lake, blaine, oakdale, minneapolis"
      imageTwitter={`${config.siteMetadata.siteUrl}/norhart-life/norhart-life-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/norhart-life/norhart-life-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="#NorhartCulture"
        subtitle="Be One Of Us And Change The World"
        imageTitle="Norhart Culture"
        image="/norhart-life/norhart-life-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Experience Norhart Culture"
        tagLine="Norhart is built on values and culture"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <RickRollNorhartLife
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="#NorhartCulture"
        title="The Norhart Mindset"
        image="/norhart-life/norhart-team-mindset.png"
        imageAlt="Norhart Support Team"
        textColor={colorPalette.textColor}
        asoEffect="fade-in"
      />

      <TwoImageSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        fontTextColor="#FFFFFF"
        icon={ApartmentIcon}
        iconTitle="Norhart Roadmap"
        title="The Norhart Vision"
        subtitle={`Our "Prime Directive" is to build exceptional apartments that create a better way for people to live. To accomplish this mission, we hire extraordinary people with exceptional skills and passion.`}
        imageTitleOne="Our Vision and Roadmap"
        imageOneText="Our 10-year roadmap simply is to be the largest smart apartment developer in the Greater Minneapolis/St. Paul area with a focus on lifestyle living, building community, and giving back."
        imageOne="/about/norhart-about-vision.webp"
        imageOneAlt="Norhart Vision and Roadmap"
        imageOnePill=""
        imageTitleTwo="Building Traction"
        imageTwoText="Norhart is bringing our vision to the ground by hiring top-notch employees, analyzing market data, becoming strong problem solvers, and defining processes to optimize our business."
        imageTwo="/about/norhart-about-traction.webp"
        imageTwoAlt="Norhart Traction"
        imageTwoPill=""
        pillActive={true}
        colorPalette={colorPalette}
        buttonName=""
        buttonUrl=""
      />

      <ThreeBoxImageSection
        backgroundColorOne="#EFF2F4"
        backgroundColorTwo="#EFF2F4"
        boxDescriptionOne="At Norhart we hire people who exhibit our values, it's just that simple."
        boxDescriptionThree="We love people who share the same passion, drive and vision as we do."
        boxDescriptionTwo="If you have the right values, talent and skill set, check out our jobs."
        boxImageOne="/norhart-life/norhart-team-values.png"
        boxImageThree="/norhart-life/norhart-passion.png"
        boxImageTwo="/norhart-life/norhart-skills.png"
        boxSubTitleOne="Values"
        boxSubTitleThree="Passion"
        boxSubTitleTwo="Skills"
        boxTitleOne="Our Traits"
        boxTitleThree="Our Traits"
        boxTitleTwo="Our Traits"
        buttonColor={colorPalette.heroTop}
        buttonName="Careers Listings"
        buttonText="#FFFFFF"
        buttonUrl="/careers/"
        colorPalette={colorPalette}
        icon={NorhartDarkCircleIcon}
        iconTitle="#NorhartCulture"
        subtitle="Yeah, we're expanding and growing fast! We are looking for passionate, talented people who share our values and vision of changing the world. If you fit the bill, we want to hear from you. #RightMeow"
        title="We ❤️ Hiring Team Players"
      />

      <HeroRightSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        fontTextColor="#FFFFFF"
        titleTopLeft="#NorhartCulture"
        badgeTitle=""
        title="#NorhartCulture Videos"
        subtitle="Watch our team videos to get a feel for our team and what it's like working at Norhart!"
        imageTitle="Norhart Life YouTube Videos"
        image="/norhart-life/norhart-life-youtube.png"
        buttonName="Watch Now"
        buttonUrl="https://www.youtube.com/c/norhart"
        colorPalette={colorPalette}
        colSizeOne={4}
        colSizeTwo={8}
        bottomMargin={0}
        asoEffect="fade"
      />

      <SixBoxSection
        backgroundColorOne="#EFF2F4"
        backgroundColorTwo="#EFF2F4"
        boxIconFive={ValuesMindsetIcon}
        boxIconFour={ValuesAttitudeIcon}
        boxIconOne={ValuesNorhartIcon}
        boxIconSix={ValuesReliableIcon}
        boxIconThree={ValuesResultsIcon}
        boxIconTwo={ValuesRespectIcon}
        boxTitleOne="Norhart"
        boxSubTitleOne="This Is The Way"
        boxDescriptionOne="At Norhart, we hire, work, and live by our values. Our values are what defines and drives our culture at Norhart."
        boxTitleTwo="Value"
        boxSubTitleTwo="Achieve Great Things"
        boxDescriptionTwo="We lead from the front, love our work, we were born to do it; we accomplish far more than we thought possible."
        boxTitleThree="Value"
        boxSubTitleThree="Improve Every Day"
        boxDescriptionThree="We question the status quo;  We believe work shouldn't be complicated. We relentlessly simplify and automate our processes."
        boxTitleFour="Value"
        boxSubTitleFour="Be A Genuine Human"
        boxDescriptionFour="We are kind, supportive, and humble. We share credit, admit our mistakes and learn from them. We are open, honest, and vulnerable."
        boxTitleFive="Value"
        boxSubTitleFive="Resident Experience Obsession"
        boxDescriptionFive="We fight to make every resident touchpoint memorable and frictionless. We care about every little detail and its impact."
        boxTitleSix="Value"
        boxSubTitleSix="Level Yourself Up"
        boxDescriptionSix="We want to be the best in the world at what we do. We want to be so much better than the competition that the fight isn't fair."
        buttonColor="#34a5ff"
        buttonName="View #NorhartCulture"
        buttonText="#FFFFFF"
        buttonUrl="/norhart-life/"
        colorPalette={colorPalette}
        icon={ValuesIcon}
        subtitle="Knowing your team and company have your back is a great feeling! We are continually expanding our reach and setting our standards exceptionally high to elevate our organization and our people."
        title="Culture of Values"
      />

      <HeroRightSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        fontTextColor="#FFFFFF"
        titleTopLeft="#NorhartCulture Immersion"
        badgeTitle=""
        title="#NorhartCulture Insta"
        subtitle="Immerse yourself in Norhart. 👊🏻 Get a feel for our people, teams, and culture. See what it's like working at Norhart!"
        imageTitle="Norhart Life YouTube Videos"
        image="/norhart-life/norhart-life-insta.png"
        buttonName="View NorhartLife"
        buttonUrl="https://www.instagram.com/norhartlife/"
        colorPalette={colorPalette}
        colSizeOne={4}
        colSizeTwo={8}
        bottomMargin={0}
        asoEffect="fade"
      />

      {/* <KeynoteSection
        backgroundColorOne="#1e4c80"
        backgroundColorTwo="#1e4c80"
        icon={VideoIcon}
        iconTitle="Watch Video"
        title="A Magical Device"
        subtitle="Imagine if we could create a special deceive, a device that could create magic. This device could change the way construction is done."
        colorPalette={colorPalette}
        posterFrame=""
        keynoteUrl="/norhart-life/video/norharts-greatest-asset-our-team.mp4"
      /> */}

      <HeroBottomSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Reviews"
        title="Our Employees Say It Best"
        starRating="yes"
        subTitle={`"The company is passionate about growth. As an employee, I am constantly learning new things. I step out and do things I've never done before because I'm not afraid to fail. My failures are seen as growth and I'm not punished for trying." - Norhart Employee`}
        image="/support/norhart-employee-reviews-1.png"
        imageAlt="Norhart Employee Reviews"
        textColor={colorPalette.textColor}
        asoEffect="zoom-in"
      />

      <HeroBottomSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        icon={NorhartDarkCircleIcon}
        iconTitle="Now Hiring"
        title="Yes! Be One Of Us!"
        subTitle="We are spreading the word in the Minneapolis/St. Paul Metro area that Norhart is hiring individuals who want to change the world one apartment at a time! Should you apply? #HellYeah"
        image="/careers/norhart-careers-construction-site-sign.png"
        imageAlt="Join The Norhart Crew"
        textColor="#FFFFFF"
        buttonName="Search Norhart Jobs"
        buttonUrl="/careers/"
      />

      <AwardsSection
        header="Norhart Apartments"
        title="What Drives Us"
        subtitle="Our culture and values propel us forward. Always be respectful, results drive success, we love to cultivate innovation, and above all, we succeed together. We fail together. That's how we roll!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
export default NorhartLifePage
