import React from "react"

/** props */
interface Props {
  backgroundColorOne: string
  backgroundColorTwo: string
  icon: any
  iconTitle: string
  title: string
  image: string
  imageAlt: string
  textColor: string
  asoEffect: string
}

/** const */
const RickRollNorhartLife: React.FC<Props> = (props) => {
  const Icon = props.icon

  return (
    <section
      className="container-fluid pt-5"
      style={{
        background: `linear-gradient(${props.backgroundColorOne} 30%, ${props.backgroundColorTwo} 70%)`,
      }}
    >
      <div className="container pl-3 pr-3 text-center">
        <div className="container pb-5"></div>
        <div className="text-center pb3">
          <Icon />
        </div>
        <div
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl text-center"
          style={{ fontSize: "15px", color: `${props.textColor}` }}
        >
          {props.iconTitle}
        </div>
        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line"
          style={{ borderColor: "rgba(0, 0, 0, 0.2)", marginTop: "10px", marginBottom: "10px" }}
        />
        <h3 className="contentHeroTitle" style={{ color: `${props.textColor}` }}>
          {props.title}
        </h3>
        <h2 className="contentHeroSubTitle mb-5" style={{ color: `${props.textColor}`, opacity: "0.8" }}>
          Values and culture first, talent and skills second. Your character, attitude, and conduct matter most. We
          pride ourselves on being a team of innovators and go-getters. But above all, we succeed together. We fail
          together, and we're{" "}
          <a
            href="/norhart-life/video/Rick-Astley-Never-Gonna-Give-You-Up-RickRoll.mp4"
            target="_blank"
            style={{ color: `${props.textColor}`, opacity: "1.0", textDecoration: "none" }}
          >
            never gonna give up
          </a>
          ! That's just how we roll! #SoSayWeAll
        </h2>
        <div>
          <img
            className="img-fluid z-depth-1 rounded"
            data-aos={props.asoEffect}
            src={props.image}
            loading="lazy"
            decoding="async"
            alt={props.imageAlt}
          />
        </div>
      </div>
    </section>
  )
}
/** export */
export default RickRollNorhartLife
